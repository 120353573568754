<template>
  <section>
    <v-row>
      <v-col cols="12">
        <div v-if="isUACI">
          <v-row>
            <v-col cols="12" sm="4">
              <v-autocomplete
                v-model="id_tipo_documento"
                :items="selectItems.data"
                item-text="nombre"
                item-value="id"
                outlined
                label="Tipo de Documento *"
                :error-messages="TipoDocumentoErrorMessage"
                @input="$v.id_tipo_documento.$touch()"
                @blur="$v.id_tipo_documento.$touch()"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" sm="4">
              <v-file-input
                outlined
                prepend-icon=""
                prepend-inner-icon="mdi-paperclip"
                label="Bases o Términos de Referencia *"
                truncate-length="15"
                v-model="documento"
                :error-messages="documentoErrors"
                @input="$v.documento.$touch()"
                @blur="$v.documento.$touch()"
              />
            </v-col>
            <v-col cols="12" sm="4">
              <v-btn
                color="secondary"
                :block="$vuetify.breakpoint.xs ? true : false"
                class="bgWithe--text"
                @click="guardarDocumento()"
                >Guardar</v-btn
              ></v-col
            >
          </v-row>
        </div>
        <v-col cols="12">
          <DataTable
            :headers="headers"
            :items="items.data?.documentos?.rows"
            v-models:select="por_pagina"
            v-models:pagina="pagina"
            :total_registros="total_registros"
            @paginar="paginar"
          >
            <template v-slot:[`item.actions`]="{ item }">
              <v-icon color="secondary" @click="descargarDocumento(item)">
                mdi-download
              </v-icon>
            </template>
            <template v-slot:[`item.fecha_carga`]="{ item }">
              {{ item.fecha_carga | moment("DD/MM/YYYY") }}
            </template>
          </DataTable>
        </v-col>
      </v-col>
    </v-row>
  </section>
</template>

<script>
import DataTable from "@/components/DataTableComponent.vue";
import { mapState, mapActions } from "vuex";
import { required } from "vuelidate/lib/validators";
export default {
  name: "AdendasEnmiendasComponent",
  components: {
    DataTable,
  },

  props: {
    idProceso: {
      type: String,
    },
    isUACI: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    documento: null,
    id_tipo_documento: null,
    pagina: 1,
    por_pagina: 5,
    total_registros: 0,
    items: [],
    selectItems: [],
    headers: [
      {
        text: "Documento",
        align: "center",
        sortable: false,
        value: "nombre_documento",
        width: "30%",
      },
      {
        text: "Tipo de documento",
        align: "center",
        value: "TipoDocumentoProceso.nombre",
        width: "20%",
      },
      { text: "Fecha de carga", value: "fecha_carga", width: "20%", align: "center" },
      { text: "Acciones", align: "center", value: "actions", width: "20%" },
    ],
    actions: [],
  }),

  validations: {
    documento: {
      required,
    },
    id_tipo_documento: {
      required,
    },
  },

  computed: {
    ...mapState("procesoCompra", [
      "lista_tipos_documentos",
      "lista_documentos",
    ]),

    documentoErrors() {
      const errors = [];
      if (!this.$v.documento.$dirty) return errors;
      !this.$v.documento.required && errors.push("Debe cargar un documento");
      return errors;
    },
    TipoDocumentoErrorMessage() {
      const errors = [];
      if (!this.$v.id_tipo_documento.$dirty) return errors;
      !this.$v.id_tipo_documento.required &&
        errors.push("Debe seleccionar tipo de documento");
      return errors;
    },
  },
  methods: {
    ...mapActions("procesoCompra", [
      "getListaDocumentosProcesoCompra",
      "getDocumentosProcesoCompra",
      "postDocumentoProcesoCompra",
      "deleteDocumentoProcesoCompra",
    ]),
    async guardarDocumento() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        try {
          let formData = new FormData();
          formData.append("documento", this.documento);
          formData.append("id_tipo_documento", this.id_tipo_documento);
          const response = await this.services.Paac.postDocumentoAdendas(
            this.idProceso,
            formData
          );
          if (response.status == 200) {
            this.getDocuments();
            this.temporalAlert({
              show: true,
              type: "success",
              message: "Archivo cargado correctamente",
            });
          }
          this.documento = null;
          this.id_tipo_documento = null;
          this.$v.documento.$reset()
          this.$v.id_tipo_documento.$reset()
        } catch (error) {
          //
        }
      }
    },
    async descargarDocumento(item) {
      const response =
        await this.services.Paac.downloadDocumentoProcesoCompra(
          item.ruta_documento
        );
      if (!response) {
        this.temporalAlert({
          show: true,
          message: "Ocurrió un error al descargar el documento",
          type: "error",
        });
      } else {
        const fileUrl = window.URL.createObjectURL(new Blob([response.data]));
        const fileLink = document.createElement("a");

        fileLink.href = fileUrl;
        fileLink.setAttribute("download", `${item.nombre_documento}`);
        document.body.appendChild(fileLink);
        fileLink.click();
      }
    },
    async getDocuments() {
      let params = {
        page: this.pagina,
        per_page: this.por_pagina,
      };
      this.items = await this.services.Paac.getAdendasEnmiedas(
        this.idProceso,
        params
      );
      this.total_registros = this.items.data.documentos.count;
      const filtro = {
        etapa: 3,
      };
      this.selectItems =
        await this.services.Paac.getListaDocumentosProcesoCompra(filtro);
    },
    paginar(filtros) {
      const { cantidad_por_pagina, pagina } = filtros;
      this.pagina = pagina;
      this.por_pagina = cantidad_por_pagina;
      this.getDocuments();
    },
  },
  created() {
    this.getDocuments();
  },
};
</script>

<style></style>
