<template>
  <section>
    <v-row no-gutters>
      <v-col cols="12" md="2" class="d-flex flex-column mb-6">
        <span> Salas disponibles: </span>

        <v-list>
          <v-list-item-group v-model="list_salas">
            <v-list-item
              v-for="(item, i) in salas"
              :key="i"
            >
              <v-list-item-content @click="addSalaSeleccionada(item)">
                <v-tooltip right>
                  <template v-slot:activator="{ on, attrs }">
                    <v-list-item-title v-text="item.nombre_sala" v-bind="attrs" v-on="on"></v-list-item-title>
                  </template>
                  <span>{{ item.nombre_sala }}</span>
                </v-tooltip>
              </v-list-item-content>
            </v-list-item>
            <v-divider v-if="salas.length > 1"></v-divider>
          </v-list-item-group>
        </v-list>
      </v-col>
      
      <v-col cols="12" md="8" class="px-14">
        <div
          v-if="subastaSeleccionada != null"
          class="d-flex text-center flex-column"
        >
          <span>
            {{ subastaSeleccionada.codigo_obs }}
            {{ subastaSeleccionada.especificacion }}
          </span>
          <span
            v-if="
              moment(subastaSeleccionada.fecha_hora_inicio).isAfter(moment())
            "
            class="info--text"
          >
            la subasta empezara
            {{ moment(subastaSeleccionada.fecha_hora_inicio).fromNow() }}
            aproximadamente
          </span>
          <span
            v-else-if="
              moment(subastaSeleccionada.fecha_hora_final).isBefore(moment())
            "
            class="error--text"
          >
            la subasta ya vencio
          </span>
          
          <app-countdown
            v-else
            :date-now="moment()"
            :nextDay="moment(subastaSeleccionada.fecha_hora_final)"
            @status="reloadSubasta"
          />
          <div class="d-flex flex-column mx-auto my-6">
            <div class="text-start">
              <v-chip label color="error" dark class="my-4 mr-3">
                Mejor oferta
              </v-chip>
              <v-chip
                v-if="actualGanador"
                label
                color="info"
                dark
                class="my-4 mx-3"
              >
                Tu oferta
              </v-chip>
            </div>
            <span class="text-h1 font-weight-medium">
              {{
                Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: "USD",
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }).format(subastaSeleccionada.monto_inicial)
              }}
              USD
            </span>
          </div>
          
          <template v-if="haveRole('ROLE_FRONT_PROVEEDOR_PROCESO_COMPRA') && moment(subastaSeleccionada.fecha_hora_inicio).isBefore(moment())">
            <div class="d-flex flex-row mx-auto mt-6">
              <money-text-field-component
                ref="moneyTextField"
                v-model="cantidadOferta"
                label="Cantidad a ofertar"
                @blur="$v.cantidadOferta.$touch()"
                :error-messages="cantidadOfertaError"
                clearable
              />
              <div class="d-flex flex-column mt-2">
                <v-btn text x-small @click="incrementCantidad">
                  <v-icon> mdi-menu-up </v-icon>
                </v-btn>
                <v-btn text x-small @click="decrementCantidad">
                  <v-icon>mdi-menu-down</v-icon>
                </v-btn>
              </div>
              <v-btn
                color="secondary"
                class="text-lowercase ml-8 mt-2 px-6 py-5"
                :disabled="counting"
                @click="ofertar"
              >
                <vue-countdown
                  v-if="counting"
                  :time="timeOferta"
                  @end="counting = false"
                >
                  <template slot-scope="props">
                    <span class="fl-upper">Disponible en:</span> <br />
                    {{ props.hours }}:{{ props.minutes }}:{{ props.seconds }}
                  </template>
                </vue-countdown>
                <span v-else class="fl-upper"> Ofertar </span>
              </v-btn>
            </div>
          </template>
        </div>
      </v-col>

      <v-col cols="12" md="2">
        <div v-if="subastaProxima != null">
          <h4>Siguiente articulo:</h4>
          <div class="d-flex flex-column pl-6">
            <span>
              {{ subastaProxima.codigo_obs }}
              {{ subastaProxima.especificacion }}
            </span>
            <span class="mt-4">
              {{
                moment(subastaProxima.fecha_hora_inicio)
                  .startOf("hour")
                  .fromNow()
              }}
              aprox.
            </span>
            <span class="mt-4">
              {{
                moment(subastaProxima.fecha_hora_inicio).format(
                  "DD/MM/YYYY HH:mm a"
                )
              }}
            </span>
          </div>
        </div>
      </v-col>
    </v-row>
  </section>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import VueCountdown from "@chenfengyuan/vue-countdown";
import AppCountdown from "../../../../components/AppCountdown.vue";
import MoneyTextFieldComponent from "@/components/MoneyTextFieldComponent.vue";
import { mapState } from "vuex";

function validateCantidadOferta(value) {
  return value <= Number(this.minimoNumber);
}

export default {
  components: { AppCountdown, MoneyTextFieldComponent, VueCountdown },

  validations: {
    cantidadOferta: {
      required,
      validateCantidadOferta,
    },
  },

  data: () => ({
    btnItem: null,
    salaSeleccionada: null,
    subastaSeleccionada: null,
    subastaProxima: null,
    cantidadOferta: null,
    montoOferta: null,
    disabledBtn: false,
    timeOferta: 0,
    actualGanador: false,
    subastasActivas: [],
    counting: false,
    list_salas: null,
  }),

  computed: {
    ...mapState("etapaSubastaInversaStore", ["salas"]),
    ...mapState(["userInfo", "sockets"]),
    minimoNumber() {
      const minimo =
        this.subastaSeleccionada.monto_inicial -
        this.subastaSeleccionada.salto_oferta;
      return Number(minimo);
    },
    minimoFormat() {
      const minimo =
        this.subastaSeleccionada.monto_inicial -
        this.subastaSeleccionada.salto_oferta;
      return Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(minimo);
    },
    cantidadOfertaError() {
      const errors = [];
      if (!this.$v.cantidadOferta.$dirty) return errors;
      !this.$v.cantidadOferta.required && errors.push("Cantidad es requerida");
      !this.$v.cantidadOferta.validateCantidadOferta &&
        errors.push(
          this.minimoNumber > 0
            ? `La cantidad debe ser mayor o igual a ${this.minimoFormat} USD`
            : `No es posible realizar la oferta, ya que se alcanzó el monto mínimo de la subasta`
        );
      return errors;
    },
  },

  methods: {
    reloadSubasta(val) {
      if (val) {
        this.temporalAlert({
          show: true,
          type: "warning",
          message: "La subasta ha finalizado",
        });
        if (this.subastaProxima) {
          this.nextSubasta();
        } else {
          this.btnItem = null;
        }
      }
    },
    incrementCantidad() {
      const salto = this.subastaSeleccionada.salto_oferta;

      if (this.cantidadOferta >= this.minimoNumber) {
        this.$refs.moneyTextField?.updateValue(this.minimoNumber);
        return;
      }

      if (this.cantidadOferta + salto > this.minimoNumber) {
        this.$refs.moneyTextField?.updateValue(this.minimoNumber);
        return;
      }

      if (this.cantidadOferta % salto == 0) {
        this.$refs.moneyTextField?.updateValue((this.cantidadOferta += salto));
        return;
      } else {
        let resto = this.cantidadOferta % salto;
        let resto2 = salto - resto;
        this.$refs.moneyTextField?.updateValue((this.cantidadOferta += resto2));
        return;
      }
    },
    decrementCantidad() {
      const salto = this.subastaSeleccionada.salto_oferta;

      if (this.cantidadOferta > this.minimoNumber) {
        this.$refs.moneyTextField?.updateValue(this.minimoNumber);
        return;
      }

      if (this.cantidadOferta <= 0) {
        this.$refs.moneyTextField?.updateValue(0);
        return;
      }

      if (this.cantidadOferta % salto == 0) {
        {
          this.$refs.moneyTextField?.updateValue(
            (this.cantidadOferta -= salto)
          );
          return;
        }
      } else {
        let resto = this.cantidadOferta % salto;
        // let resto2 = salto - resto;

        this.$refs.moneyTextField?.updateValue((this.cantidadOferta -= resto));
      }
    },
    addSalaSeleccionada(sala) {
      if (sala.subastas_activas.length > 0) {
        this.subastaSeleccionada = sala.subastas_activas[0];
        this.subastaProxima = null;
        if (sala.subastas_activas.length > 1) {
          this.subastaProxima = sala.subastas_activas[1];
          this.subastasActivas = [];
          if (sala.subastas_activas.length > 2) {
            this.subastasActivas = sala.subastas_activas;
          }
        }
      }
    },
    nextSubasta() {
      let sbtS = this.subastaSeleccionada.id_subasta;
      let sbtP = this.subastaProxima.id_subasta;
      if (sbtS != sbtP) {
        this.subastaSeleccionada = this.subastaProxima;
        this.subastaProxima = this.subastasActivas.find(
          (s) => s.id_subasta == sbtS
        );
      }
    },
    async fetchOferta() {
      const id = this.subastaSeleccionada.id_subasta;
      const oferta = await this.services.Paac.getSubasta(id);
      if (oferta && !this.haveRole('ROLE_UACI')) {
        this.montoOferta = oferta.data.monto;
        this.actualGanador = oferta.data.actual_ganador;
        const date = await this.services.Paac.getGanador(id);
        if (date) {
          this.actualGanador = date.data.actual_ganador;
          const valueA = this.moment();
          const valueB = this.moment(date.data.proxima_operacion);
          const valueC = valueB.diff(valueA);
          if (valueC > 0) {
            this.timeOferta = valueC;
            this.counting = true;
          } else {
            this.timeOferta = 0;
            this.counting = false;
          }
        }
      }
    },
    async ofertar() {
      if (this.$v.cantidadOferta.$invalid) {
        this.$v.cantidadOferta.$touch();
        return;
      } else {
        try {
          const { data } = await this.services.Paac.postOferta(
            this.subastaSeleccionada.id_subasta,
            { monto: Number(this.cantidadOferta) }
          );
          if (data) {
            const valueA = this.moment();
            const valueB = this.moment(data.proxima_operacion);
            const valueC = valueB.diff(valueA);
            if (valueC > 0) {
              this.timeOferta = valueC;
              this.temporalAlert({
                show: true,
                type: "success",
                message: "Oferta realizada con éxito",
              });
              this.counting = true;
              this.fetchOferta();
            } else {
              this.timeOferta = 0;
              this.counting = false;
            }
            this.$v.cantidadOferta.$reset();
          }
        } catch (error) {
          console.log(error);
        }
      }
    },
    initSocket() {
      this.sockets.emit("SUBASTA_PARTICIPAR");
      this.sockets.subscribe(`EVENTOS_SUBASTA`, (data) => {
        if (data.id_subasta == this.subastaSeleccionada.id_subasta) {
          this.subastaSeleccionada.monto_inicial = data.monto;
          if (data.id_usuario_ganador == this.userInfo.user.id) {
            this.actualGanador = true;
          } else {
            this.actualGanador = false;
          }
        } else {
          this.subastaSeleccionada = null;
          this.temporalAlert({
            show: true,
            type: "error",
            message: "Error al cargar la subasta",
          });
        }
      });
    },
    finallySocket() {
      this.sockets.unsubscribe(`EVENTOS_SUBASTA`);
      this.sockets.emit("SUBASTA_ABANDONAR");
    },
  },

  watch: {
    btnItem: function (val) {
      val ? null : (this.subastaSeleccionada = null);
    },
    subastaSeleccionada: function () {
      if (this.$refs.moneyTextField != undefined) {
        this.$refs.moneyTextField?.clear();
      }
      this.cantidadOferta = null;
      this.$v.cantidadOferta.$reset();
      if (this.subastaSeleccionada?.id_subasta) {
        this.fetchOferta();
      }
    },
    montoOferta: function (value) {
      if (value) {
        this.subastaSeleccionada.monto_inicial = value;
      }
    },
  },
  created() {
    this.initSocket();
  },
  beforeDestroy() {
    this.finallySocket();
  },
};
</script>
<style lang="scss" scoped>
.fl-upper::first-letter {
  text-transform: uppercase !important;
}

.w-button-group {
  max-width: max-content;
}

:deep(.v-btn-toggle) {
  flex-direction: column;
}

:deep(.v-btn-toggle--group > .v-btn.v-btn--active) {
  background-color: #404a7a !important;
  color: white !important;
}
</style>
