var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-expansion-panel',{ref:"expansionpanel"},[_c('v-expansion-panel-header',{attrs:{"color":"bgMinsal"}},[_c('v-row',[_c('v-col',{staticClass:"py-0 my-0 px-0",attrs:{"cols":"12"}},[_c('p',{staticClass:"mb-1"},[_c('span',{staticClass:"secondary--text"},[_vm._v(_vm._s(_vm.consulta.proveedor_nombre))]),_c('span',{staticClass:"grey--text ml-2"},[_vm._v(_vm._s(_vm.fechaHaceTiempo(_vm.consulta.fecha)))]),_c('v-chip',{staticClass:"white--text ml-3",attrs:{"label":"","color":_vm.consulta.color,"small":""}},[(_vm.consulta.id_estado == 1)?_c('span',[_vm._v("Presentada")]):_vm._e(),(_vm.consulta.id_estado == 2)?_c('span',[_vm._v("Rechazada")]):_vm._e(),(_vm.consulta.id_estado == 3)?_c('span',[_vm._v("Publicada")]):_vm._e()])],1)]),_c('v-col',{class:_vm.consulta.id_estado == 1 || _vm.consulta.id_estado == 3
          ? 'pt-1 pb-2 pl-0'
          : 'pt-1 pb-8 pl-0',attrs:{"cols":"12"}},[_vm._v(" "+_vm._s(_vm.consulta.consulta)+" "),(_vm.consulta.nombre != null && _vm.consulta.ruta != null)?_c('div',[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"secondary"}},[_vm._v("mdi-file-pdf-box")]),_c('a',{on:{"click":_vm.descargarArchivo}},[_vm._v(_vm._s(_vm.decodeString(_vm.consulta.nombre)))])],1):_vm._e()])],1)],1),_c('v-expansion-panel-content',{staticClass:"pt-5"},[_vm._l((_vm.consulta.respuestas),function(respuesta){return _c('consulta-respuesta-uaci-component',{key:respuesta.id,staticClass:"pl-4",attrs:{"estado":_vm.consulta.id_estado,"fecha":respuesta.fecha,"id":respuesta.id,"idConsulta":_vm.consulta.id,"nombre":respuesta.nombre,"responsable":respuesta.institucion_nombre ?? respuesta.proveedor_nombre,"respuesta":respuesta.consulta,"ruta":respuesta.ruta}})}),(_vm.consulta.id_estado == 1 || _vm.consulta.id_estado == 3)?_c('div',{staticClass:"py-3"},[(
        _vm.consulta.id_estado == 3 || (_vm.consulta.id_estado == 1 && _vm.isUaci)
      )?_c('v-btn',{attrs:{"text":"","outlined":"","color":"secondary"},on:{"click":_vm.mostrarResponder},nativeOn:{"click":function($event){$event.stopPropagation();}}},[_c('v-icon',[_vm._v("mdi-arrow-left")]),_vm._v(" Responder ")],1):_vm._e()],1):_vm._e(),(
      (_vm.consulta.id_estado == 3 || (_vm.consulta.id_estado == 1 && _vm.isUaci)) &&
      _vm.tipoFormulario == 1
    )?_c('v-row',{staticClass:"mt-0"},[_c('v-col',{attrs:{"cols":"7"}},[_c('v-row',{staticClass:"my-0 py-0"},[_c('v-col',{staticClass:"my-0 py-0",attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"error-messages":_vm.respuestaErrors,"label":"Comentar","placeholder":"Agregue una respuesta","outlined":"","rows":"3"},model:{value:(_vm.respuesta),callback:function ($$v) {_vm.respuesta=$$v},expression:"respuesta"}})],1)],1),_c('v-row',{staticClass:"my-0 py-0"},[_c('v-col',{staticClass:"my-0 py-0",attrs:{"cols":"12"}},[_c('v-file-input',{staticClass:"mt-5",attrs:{"outlined":"","prepend-icon":"","label":"Adjunto (opcional)","append-icon":"mdi-paperclip"},model:{value:(_vm.adjunto),callback:function ($$v) {_vm.adjunto=$$v},expression:"adjunto"}})],1)],1)],1),(_vm.consulta.id_estado == 3)?_c('v-col',{attrs:{"cols":"2"}},[_c('v-btn',{staticClass:"mt-8",attrs:{"outlined":"","loading":_vm.isLoadingAgregar,"color":"secondary"},on:{"click":function($event){return _vm.responder()}}},[_vm._v(" Agregar ")])],1):_vm._e(),(_vm.consulta.id_estado == 1 && _vm.isUaci)?_c('v-col',{staticClass:"py-0 my-0",attrs:{"cols":"2"}},[_c('div',[_c('v-btn',{staticClass:"mt-4",attrs:{"outlined":"","loading":_vm.isLoadingAgregar,"disabled":_vm.isLoadingRechazar,"width":"100%","color":"green"},on:{"click":function($event){return _vm.responder(3)}}},[_vm._v(" Publicar ")]),_c('v-btn',{staticClass:"mt-4",attrs:{"outlined":"","loading":_vm.isLoadingRechazar,"disabled":_vm.isLoadingAgregar,"width":"100%","color":"red"},on:{"click":function($event){return _vm.responder(2)}}},[_vm._v(" Rechazar ")])],1)]):_vm._e()],1):_vm._e(),(
      (_vm.consulta.id_estado == 3 || _vm.consulta.id_estado == 1) &&
      _vm.tipoFormulario == 2
    )?_c('v-row',{staticClass:"mt-0"},[_c('v-col',{attrs:{"cols":"7"}},[_c('v-textarea',{attrs:{"error-messages":_vm.editadoErrors,"label":"Comentar","placeholder":"Agregue una respuesta","outlined":"","rows":"3"},model:{value:(_vm.editado),callback:function ($$v) {_vm.editado=$$v},expression:"editado"}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-btn',{staticClass:"mt-8",attrs:{"outlined":"","color":"secondary"},on:{"click":function($event){return _vm.editar()}}},[_vm._v(" Editar ")])],1)],1):_vm._e()],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }