<template>
  <v-expansion-panel ref="expansionpanel">
    <v-expansion-panel-header color="bgMinsal">
      <v-row>
        <v-col cols="12" class="py-0 my-0 px-0">
          <p class="mb-1">
            <span class="secondary--text">{{ consulta.proveedor_nombre }}</span>
            <span class="grey--text ml-2">{{
              fechaHaceTiempo(consulta.fecha)
            }}</span>
            <v-chip label :color="consulta.color" small class="white--text ml-3">
              <span v-if="consulta.id_estado == 1">Presentada</span>
              <span v-if="consulta.id_estado == 2">Rechazada</span>
              <span v-if="consulta.id_estado == 3">Publicada</span>
            </v-chip>
          </p>
        </v-col>
        <v-col cols="12" :class="
          consulta.id_estado == 1 || consulta.id_estado == 3
            ? 'pt-1 pb-2 pl-0'
            : 'pt-1 pb-8 pl-0'
        ">
          {{ consulta.consulta }}
          <div v-if="consulta.nombre != null && consulta.ruta != null">
            <v-icon class="mr-2" color="secondary">mdi-file-pdf-box</v-icon>
            <a @click="descargarArchivo">{{ decodeString(consulta.nombre) }}</a>
          </div>
        </v-col>
      </v-row>
    </v-expansion-panel-header>
    <v-expansion-panel-content class="pt-5">
      <consulta-respuesta-uaci-component 
        :estado="consulta.id_estado"
        :fecha="respuesta.fecha" 
        :id="respuesta.id"
        :idConsulta="consulta.id" 
        :key="respuesta.id" 
        :nombre="respuesta.nombre" 
        :responsable="respuesta.institucion_nombre ?? respuesta.proveedor_nombre"
        :respuesta="respuesta.consulta" 
        :ruta="respuesta.ruta"
        class="pl-4"
        v-for="respuesta in consulta.respuestas" 
      />

      <div v-if="consulta.id_estado == 1 || consulta.id_estado == 3" class="py-3">
        <v-btn v-if="
          consulta.id_estado == 3 || (consulta.id_estado == 1 && isUaci)
        " text @click.native.stop @click="mostrarResponder" outlined color="secondary">
          <v-icon>mdi-arrow-left</v-icon>
          Responder
        </v-btn>
      </div>

      <v-row class="mt-0" v-if="
        (consulta.id_estado == 3 || (consulta.id_estado == 1 && isUaci)) &&
        tipoFormulario == 1
      ">
        <v-col cols="7">
          <v-row class="my-0 py-0">
            <v-col cols="12" class="my-0 py-0">
              <v-textarea :error-messages="respuestaErrors" v-model="respuesta" label="Comentar"
                placeholder="Agregue una respuesta" outlined rows="3"></v-textarea>
            </v-col>
          </v-row>
          <v-row class="my-0 py-0">
            <v-col cols="12" class="my-0 py-0">
              <v-file-input class="mt-5" outlined prepend-icon="" label="Adjunto (opcional)" append-icon="mdi-paperclip"
                v-model="adjunto" />
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="2" v-if="consulta.id_estado == 3">
          <v-btn outlined class="mt-8" @click="responder()" :loading="isLoadingAgregar" color="secondary">
            Agregar
          </v-btn>
        </v-col>
        <v-col cols="2" v-if="consulta.id_estado == 1 && isUaci" class="py-0 my-0">
          <div>
            <v-btn outlined :loading="isLoadingAgregar" :disabled="isLoadingRechazar" class="mt-4" width="100%"
              color="green" @click="responder(3)">
              Publicar
            </v-btn>
            <v-btn outlined :loading="isLoadingRechazar" :disabled="isLoadingAgregar" class="mt-4" width="100%"
              color="red" @click="responder(2)">
              <!-- Este botón es para rechazar una pregunta y que no este visible para otros proveedores -->
              Rechazar
            </v-btn>
          </div>
        </v-col>
      </v-row>
      <v-row class="mt-0" v-if="
        (consulta.id_estado == 3 || consulta.id_estado == 1) &&
        tipoFormulario == 2
      ">
        <v-col cols="7">
          <v-textarea :error-messages="editadoErrors" v-model="editado" label="Comentar"
            placeholder="Agregue una respuesta" outlined rows="3"></v-textarea>
        </v-col>
        <v-col cols="2">
          <v-btn outlined class="mt-8" color="secondary" @click="editar()">
            Editar
          </v-btn>
        </v-col>
      </v-row>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>
<script>
import { mapActions, mapState } from "vuex";
import ConsultaRespuestaUaciComponent from "./ConsultaRespuestaUaciComponent.vue";
import { requiredIf } from "vuelidate/lib/validators";

// Validar que el campo adjunto no supere los 512kb
const maxSize = (value) => {
  if (value) {
    return value.size <= 150000000;
  }
  return true;
};

// Validar que el archivo adjunto sea un pdf
const isPdf = (value) => {
  if (value) {
    return value.type == "application/pdf";
  }
  return true;
};

export default {
  name: "ConsultaComponent",
  components: {
    ConsultaRespuestaUaciComponent,
  },
  props: {
    consulta: {
      type: Object,
      required: true,
    },
    isUaci: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      editado: "",
      comentario: "",
      tipoFormulario: 0, // 0: Sin formulario 1: Consulta, 2: Editar
      respuesta: null,
      adjunto: null,
    };
  },
  validations: {
    respuesta: {
      requiredIf: requiredIf(function () {
        return this.tipoFormulario == 1;
      }),
    },
    adjunto: {
      maxSize,
      isPdf,
    },
    editado: {
      requiredIf: requiredIf(function () {
        return this.tipoFormulario == 2;
      }),
    },
  },
  methods: {
    ...mapActions("etapaConsultasStore", [
      "responderConsulta",
      "editarConsulta",
    ]),

    decodeString(string) {
      return decodeURIComponent(escape(string));
    },

    async descargarArchivo() {
      const response = await this.services.Paac.descargarDocumentoConstulas(
        this.consulta.id,
        this.consulta.ruta
      );

      if (response.status == 200) {
        const blob = new Blob([response.data], {
          type: "application/pdf",
        });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = this.consulta.ruta;
        link.click();
      }
    },
    async responder(id_estado = null) {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      const formData = new FormData();
      formData.append("consulta", this.respuesta);
      formData.append("id_consulta", parseInt(this.consulta.id));
      formData.append("id_estado", parseInt(id_estado));
      formData.append("documento", this.adjunto);
      const response = await this.responderConsulta(formData);
      if (response.status == 201) {
        this.respuesta = "";
        this.adjunto = null;
        // Reiniciar validaciones
        this.$v.$reset();
      }
    },
    async editar() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      const formData = new FormData();
      formData.append("consulta", this.editado);
      formData.append("id_consulta", this.consulta.id);
      formData.append("documento", this.adjunto);

      const response = await this.editarConsulta(formData);
      if (response.status == 200) {
        this.editado = "";
        // Reiniciar validaciones
        this.$v.$reset();
      }
    },
    mostrarCajaRespuesta() {
      this.mostrar_caja_texto = true;
    },
    ocultarCajaRespuesta() {
      this.mostrar_caja_texto = false;
    },
    mostrarResponder() {
      if (!this.$refs.expansionpanel.isActive) {
        this.$refs.expansionpanel.isActive = true;
      }
      this.tipoFormulario = 1;
    },
    mostrarEditar() {
      if (!this.$refs.expansionpanel.isActive) {
        this.$refs.expansionpanel.isActive = true;
      }
      this.tipoFormulario = 2;
    },
  },
  computed: {
    ...mapState("etapaConsultasStore", [
      "isLoadingAgregar",
      "isLoadingRechazar",
    ]),
    respuestaErrors() {
      const errors = [];
      if (!this.$v.respuesta.$dirty) return errors;
      !this.$v.respuesta.required && errors.push("El campo es requerido");
      return errors;
    },
    editadoErrors() {
      const errors = [];
      if (!this.$v.editado.$dirty) return errors;
      !this.$v.editado.required && errors.push("El campo es requerido");
      return errors;
    },
  },
  created() {
    this.editado = this.consulta.consulta;
  },
};
</script>
<style lang="scss" scoped>
:deep(.v-expansion-panel-content__wrap) {
  padding: 0px !important;
}
</style>
