<template>
  <section>
    <v-row>
      <v-col cols="12" v-if="!isUaci">
        <v-btn outlined color="secondary" @click="showModal = true">
          Cargar</v-btn
        >
      </v-col>
      <v-col cols="12">
        <DataTable
          v-models:select="por_pagina"
          v-models:pagina="pagina"
          :total_registros="total_registro"
          :headers="headers"
          :items="items"
          :show_actions="true"
          :actions="actions"
          @downloadFile="downloadFile"
          @paginar="paginar"
        >
        <template #[`item.fecha_carga`]="{ item }">
          {{ moment(item.fecha_carga).format("DD/MM/YYYY") }}
        </template>
          <template v-slot:[`item.legal`]="{ item }">
            <v-icon dark :color="getColor(item.legal)">
              mdi-check-circle
            </v-icon>
          </template>
        </DataTable>
      </v-col>
    </v-row>
    <!-- Modal para ver la información -->
    <app-dialog :max-width="500" v-model="showModal" title="Carga de recursos">
      <template #content>
        <v-row>
          <v-col cols="12">
            <v-file-input
              outlined
              prepend-icon=""
              prepend-inner-icon="mdi-paperclip"
              label="Recurso"
              truncate-length="15"
              v-model="documento"
              @input="$v.documento.$touch()"
              @blur="$v.documento.$touch()"
              :error-messages="documentoErrors"
            />
          </v-col>
        </v-row>
      </template>
      <template #actions>
        <div class="mt-3">
          <v-btn color="secondary" @click="sendFile" class="mr-4" outlined>
            Enviar
          </v-btn>
        </div>
      </template>
    </app-dialog>
  </section>
</template>

<script>
import DataTable from "@/components/DataTableComponent.vue";
import { required } from "vuelidate/lib/validators";
export default {
  props: {
    isUaci: {
      type: Boolean,
      default: false,
    },
    idProceso: {
      type: String,
    },
  },
  components: {
    DataTable,
  },
  name: "RecursoRevisionUACIComponent",
  data: () => ({
    documento: null,
    showModal: false,
    pagina: 1,
    por_pagina: 5,
    total_registro: 0,
    response: [],
    items: [],
    headers: [
      {
        text: "Recurso",
        align: "start",
        sortable: true,
        value: "nombre_documento",
        width: "30%",
      },
      { text: "Fecha de entrega", value: "fecha_carga", sortable: true },
      {
        text: "Proveedor",
        value: "Usuario.Proveedor.nombre_comercial",
        sortable: true,
      },
      { text: "Acciones", align: "center", value: "actions" },
    ],
    actions: [
      {
        tooltip: {
          text: "Descargar archivo",
          color: "blueGrayMinsal",
        },
        icon: {
          color: "secondary",
          name: "mdi-download",
        },
        eventName: "downloadFile",
      },
    ],
  }),
  validations: {
    documento: {
      required,
    },
  },
  methods: {
    async obtainEtapaActual() {
      let params = {
        pagination: true,
        page: this.pagina,
        per_page: this.por_pagina,
      };
      this.response = await this.services.Paac.getEtapaActualPaginacion(this.idProceso, params);
      this.items = this.response.data.documentos.rows;
      this.total_registro = Number(this.response.headers.total_rows);
    },
    async downloadFile({ id_proceso, id }) {
      const res = await this.services.Paac.getDocumentRecursoRevision(
        id_proceso,
        id
      );
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "RecursoRevision.pdf");
      document.body.appendChild(link);
      link.click();
    },
    async sendFile() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        try {
          let formData = new FormData();
          formData.append("documento", this.documento);
          const response =
            await this.services.Paac.postDocumentoEtapasProcesoCompraProveedor(
              Number(this.idProceso),
              formData
            );
          if (response.status == 200) {
            this.temporalAlert({
              show: true,
              type: "success",
              message: "Archivo cargado correctamente",
            });
            this.obtainEtapaActual();
          }
          this.documento = null;
          this.showModal = false;
        } catch (error) {
          console.log(error);
        }
      }
    },
    paginar(filtros) {
      const { cantidad_por_pagina, pagina } = filtros;
      this.pagina = pagina;
      this.por_pagina = cantidad_por_pagina;
      this.obtainEtapaActual();
    },
  },
  computed: {
    documentoErrors() {
      const errors = [];
      if (!this.$v.documento.$dirty) return errors;
      !this.$v.documento.required && errors.push("Debe cargar un documento");
      return errors;
    },
  },
  created() {
    this.obtainEtapaActual();
  },
};
</script>

<style></style>
