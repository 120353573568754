<template>
  <section>
    <v-row>
      <v-col cols="12">
        <v-row>
          <v-col cols="12" sm="4">
            <v-text-field
              label="Especificación"
              outlined
              clearable
              append-icon="mdi-magnify"
              v-model="especificacion"
              @change="etapaActualConFiltros"
              @click:clear="limpiarFiltro"
            />
          </v-col>
          <v-col cols="12" sm="4" v-if="isUaci">
            <v-select
              label="Proveedor"
              outlined
              clearable
              :items="lista_proveedores"
              item-text="nombre"
              item-value="id"
              v-model="proveedor"
              @change="etapaActualConFiltros"
              @click:clear="limpiarFiltro"
            />
          </v-col>
          <v-col cols="12" sm="4">
            <v-select
              label="OBS"
              :items="lista_obs"
              item-text="nombre"
              item-value="id"
              outlined
              clearable
              v-model="obs"
              @change="etapaActualConFiltros"
              @click:clear="limpiarFiltro"
            />
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12">
        <DataTable
          :headers="headers"
          :items="listaAperturaOfertas"
          :show_actions="true"
          :actions="actions"
          :tiene_paginacion="false"
          @show="show"
        >
        </DataTable>
      </v-col>
    </v-row>
    <!-- Modal -->
    <AppDialog v-model="value" title="Oferta presentada" :maxWidth="1000">
      <template #content>
        <v-row>
          <v-col cols="12" sm="5">
            <v-row>
              <v-col cols="12">
                <span>Nombre del proveedor</span>
                <p class="font-weight-black">
                  {{ info.ProveedorParticipacionObs[0]?.Proveedor?.nombre }}
                </p>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <span>Lote</span>
                <p class="font-weight-black">{{ info.articulo }}</p>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <span>Especificación</span>
                <p class="font-weight-black">
                  {{ info.especificacion }}
                </p>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12" sm="7">
            <v-card elevation="5">
              <v-card-text>
                <v-card-title style="color: #404a7a"
                  >Formulario de oferta</v-card-title
                >
                <v-row>
                  <v-col cols="12">
                    <v-file-input
                      outlined
                      label="Oferta"
                      prepend-icon=""
                      prepend-inner-icon="mdi-paperclip"
                      disabled
                      v-model="documento"
                    >
                      <template v-slot:append-outer>
                        <v-icon @click="obtenerDocumentoBases" color="green"
                          >mdi-file-download</v-icon
                        >
                      </template>
                    </v-file-input>
                  </v-col>
                </v-row>
                <v-row v-if="false">
                  <v-col cols="6">
                    <MoneyInput ref="mtfPrecioUnitario" label="Precio unitario" :value="ofertaSeleccionada?.precio_unitario" :disabled="true"/>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      outlined
                      ref="mtfCantidad"
                      label="Cantidad"
                      min="0"
                      type="number"
                      :value="ofertaSeleccionada?.cantidad"
                      disabled
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <MoneyInput
                      ref="mtfTotal"
                      label="Total oferta"
                      :value="ofertaSeleccionada?.total"
                      :disabled="true"
                    />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </template>
      <template #actions>
        <div>
          <v-btn color="secondary" @click="value = false" class="mr-4" outlined>
            Cerrar
          </v-btn>
        </div>
      </template>
    </AppDialog>
    <!--FIN  Modal -->
  </section>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import DataTable from "@/components/DataTableComponent.vue";
import MoneyInput from "@/components/MoneyTextFieldComponent.vue";

export default {
  components: {
    DataTable,
    MoneyInput,
  },
  props: {
    idProceso: {
      type: String,
    },
    isUaci: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    value: false,
    documento: null,
    document_path: null,
    document_name: null,
    especificacion: null,
    proveedor: null,
    obs: null,
    headers: [
      {
        text: "Especificación",
        align: "start",
        sortable: false,
        value: "nombre_agrupacion",
        width: "50%",
      },
      // { text: "OBS", value: "nombre_ob", width: "20%" },
      { text: "Proveedor", value: "nombre_proveedor", width: "20%" },
      { text: "Cantidad", align: "center", value: "cantidad", width: "10%" },
      { text: "Acciones", align: "center", value: "actions", width: "20%" },
    ],
    actions: [
      {
        tooltip: {
          text: "Ver",
          color: "blueGrayMinsal",
        },
        icon: {
          color: "secondary",
          name: "mdi-eye",
        },
        eventName: "show",
      },
    ],
    ofertaSeleccionada: {},
  }),
  computed: {
    ...mapState("etapaAperturaOfertaStore", [
      "lista_proveedores",
      "lista_obs",
      "listaAperturaOfertas",
      "info",
    ]),
  },
  methods: {
    ...mapActions("etapaAperturaOfertaStore", [
      "getProveedoresAperturaOferta",
      "getObsAperturaOferta",
      "getDetalleAperturaOferta",
      "getDocumento",
    ]),
    ...mapMutations("etapaAperturaOfertaStore", ["setListaAperturaOferta"]),
    async limpiarFiltro() {
      this.especificacion = null;
      this.proveedor = null;
      this.obs = null;
      const response = await this.services.Paac.getEtapaActual(this.idProceso);
      if (response) {
        this.setListaAperturaOferta(response.data.data);
      }
    },
    async etapaActualConFiltros() {
      if (
        this.especificacion !== null ||
        this.proveedor !== null ||
        this.obs !== null
      ) {
        let filters = {};
        if (this.especificacion !== null)
          filters.especificacion = this.especificacion;
        if (this.proveedor !== null) filters.id_proveedor = this.proveedor;
        if (this.obs !== null) filters.id_obs = this.obs;
        const response = await this.services.Paac.getEtapaActual(
          this.idProceso,
          filters
        );
        if (response) {
          this.setListaAperturaOferta(response.data.data);
        }
      }
    },
    async documentoBases() {
      const response = await this.getDocumento(this.document_path);
      if (!response) {
        this.temporalAlert({
          show: true,
          message: "Ocurrió un error al descargar el documento",
          type: "error",
        });
      } else {
        const blob = new Blob([response], {
          type: "application/pdf",
        });
        this.documento = new File([blob], `${this.document_name}`, {
          type: "application/pdf",
        });
      }
    },
    async obtenerDocumentoBases() {
      const response = await this.getDocumento(this.document_path);
      if (!response) {
        this.temporalAlert({
          show: true,
          message: "Ocurrió un error al descargar el documento",
          type: "error",
        });
      } else {
        const fileUrl = window.URL.createObjectURL(new Blob([response]));
        const fileLink = document.createElement("a");

        fileLink.href = fileUrl;
        fileLink.setAttribute("download", `${this.document_name}.pdf`);
        document.body.appendChild(fileLink);
        fileLink.click();
      }
    },
    async show(item) {
      let data = {
        id_proveedor: item.id_proveedor,
        id_proceso_compra: this.idProceso,
        id_sub_proceso_obs: item.id_subproceso,
      };
      await this.getDetalleAperturaOferta(data);
      this.document_path = this.info.ProveedorParticipacionObs[0].url;
      this.document_name =
        this.info.ProveedorParticipacionObs[0].nombre_documento.split("-")[0];

      this.ofertaSeleccionada = {
        precio_unitario: this.info?.ProveedorParticipacionObs[0].precio_unitario,
        cantidad: this.info?.ProveedorParticipacionObs[0].cantidad,
        total: this.info?.ProveedorParticipacionObs[0].total,
      };
      this.value = true;
    },
  },
  watch: {
    "ofertaSeleccionada.precio_unitario": function (value) {
      if (value) {
        this.$refs.mtfPrecioUnitario.updateValue(value);
      } else {
        this.$refs.mtfPrecioUnitario.clear();
      }
    },
    "ofertaSeleccionada.cantidad": function (value) {
      if (value) {
        this.$refs.mtfCantidad.updateValue(value);
      } else {
        this.$refs.mtfCantidad.clear();
      }
    },
    "ofertaSeleccionada.total": function (value) {
      if (value) {
        this.$refs.mtfTotal.updateValue(value);
      } else {
        this.$refs.mtfTotal.clear();
      }
    },
  },
  async created() {
    await this.getProveedoresAperturaOferta(this.idProceso);
    await this.getObsAperturaOferta(this.idProceso);
  },
};
</script>
