<template>
  <section>
    <v-row>
      <v-col cols="12">
        <v-row>
          <v-col cols="12" sm="4">
            <v-text-field
              append-icon="mdi-magnify"
              label="Especificación"
              outlined
              v-model="especificacion_model"
              @keydown="obtenerResultadosPreliminares"
            >
            </v-text-field>
          </v-col>
          <v-col v-if="isUaci" cols="12" sm="4">
            <v-select
              clearable
              label="Proveedor"
              item-text="nombre"
              item-value="id"
              outlined
              v-model="id_proveedor_model"
              :items="proveedor_options"
              @change="obtenerResultadosPreliminares"
            >
            </v-select>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12">
        <DataTable
          v-models:select="por_pagina_model"
          v-models:pagina="pagina_model"
          :headers="idEtapa === 13 && info_general.id_forma_contratacion == 3 ? headersSubastaInversa : headers"
          :items="resultados"
          :actions="actions"
          :total_registros="total_registros"
          @paginar="paginar"
        >
          <template v-slot:[`item.evaluacion_legal`]="{ item }">
            <v-icon dark v-if="item.evaluacion_legal" color="green">
              mdi-check-circle
            </v-icon>
            <v-icon dark v-else color="red"> mdi-close-circle </v-icon>
          </template>

          <template v-slot:[`item.oferta`]="{ item }">
            {{
              Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
              }).format(item.oferta)
            }}
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <v-btn icon @click="verDetalle(item.id)">
              <v-icon>mdi-eye</v-icon>
            </v-btn>
          </template>
        </DataTable>
      </v-col>
    </v-row>
    <!-- Modal -->
    <AppDialog v-model="showModal" title="Oferta presentada" :maxWidth="1000">
      <template #content>
        <v-row>
          <v-col cols="12" sm="5">
            <v-row>
              <v-col cols="12">
                <span>Nombre del proveedor</span>
                <p class="font-weight-black">{{ participante.proveedor }}</p>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <span>Lote</span>
                <p class="font-weight-black">{{ participante.articulo }}</p>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <span>Especificación</span>
                <p class="font-weight-black">
                  {{ participante.especificacion }}
                </p>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12" sm="7">
            <v-card elevation="5">
              <v-card-text>
                <v-card-title style="color: #404a7a"
                  >Formulario de oferta</v-card-title
                >
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      :prepend-icon="null"
                      prepend-inner-icon="mdi-paperclip"
                      outlined
                      label="Oferta"
                      readonly
                      v-model="participante.documento"
                    >
                      <template v-slot:append>
                        <v-icon @click="descargarDocumento" color="green"
                          >mdi-file-download</v-icon
                        >
                      </template>
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row v-if="false">
                  <v-col cols="6">
                    <MoneyInput
                      ref="inputPrecioMT"
                      readonly
                      label="Precio unitario"
                      v-model="participante.precio_unitario"
                    />
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      readonly
                      v-model="participante.cantidad"
                      outlined
                      label="Cantidad"
                      min="0"
                      type="number"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <MoneyInput
                    ref="inputTotalMT"
                      label="Total oferta"
                      readonly
                      v-model="participante.total"
                    />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </template>
      <template #actions>
        <div>
          <v-btn color="secondary" @click="showModal = false" class="mr-4" outlined>
            Cerrar
          </v-btn>
        </div>
      </template>
    </AppDialog>
    <!--FIN  Modal -->
  </section>
</template>

<script>
import DataTable from "@/components/DataTableComponent.vue";
import MoneyInput from "@/components/MoneyTextFieldComponent.vue";
import { mapActions, mapMutations, mapState } from "vuex";
export default {
  components: {
    DataTable,
    MoneyInput,
  },
  props: {
    isUaci: {
      type: Boolean,
      default: false,
    },
    idEtapa: {
      type: Number,
    }
  },
  data: () => ({
    precio_unitario: 0,
    showModal: false,
    items: [
      {
        especificacion: "Papel bond -  1",
        proveedor: "Proveedor 1",
        oferta: "oferta 1",
        financiera: 10,
        tecnica: 8,
        legal: 1,
        otorgado: 100,
      },
      {
        especificacion: "Papel bond - 2",
        proveedor: "Proveedor 1",
        oferta: "oferta 1",
        financiera: 10,
        tecnica: 9,
        legal: 2,
        otorgado: 300,
      },
    ],
    headers: [
      {
        text: "Especificación",
        align: "start",
        sortable: false,
        value: "especificacion",
        width: "25%",
      },
      { text: "Proveedor", value: "nombre_proveedor" },
      { text: "Oferta($)", value: "oferta" },
      { text: "Evaluación financiera", value: "evaluacion_financiera" },
      { text: "Evaluación técnica", value: "evaluacion_tecnica" },
      { text: "Evaluación legal", value: "evaluacion_legal" },
      { text: "% Otorgado", value: "porcentaje_otorgado" },
      { text: "Acciones", align: "center", value: "actions" },
    ],
    headersSubastaInversa: [
      {
        text: "Subasta",
        align: "start",
        sortable: false,
        value: "subasta",
        width: "25%",
      },
      { text: "Sala", value: "sala", align:"center"},
      { text: "Proveedor", value: "nombre_proveedor", align:"center" },
      { text: "Oferta($)", value: "oferta", align:"center" },
      { text: "Estado", value: "estado", align:"center" },
    ],
    actions: [
      {
        tooltip: {
          text: "Ver",
          color: "blueGrayMinsal",
        },
        icon: {
          color: "secondary",
          name: "mdi-eye",
        },
        eventName: "show",
      },
    ],

    proveedorSelect: ["Proveedor 1", "Proveedor 2"],
    obsSelect: ["Obs 1", "Obs 2"],
    especificacionData: ["Esp 1", "Esp 2"],
  }),
  computed: {
    ...mapState("etapaResultadosPreliminares", [
      "resultados",
      "cantidad_por_pagina",
      "pagina_actual",
      "total_registros",
      "participante",
      "proveedor_options",
      "especificacion",
    ]),
    ...mapState("procesoCompra", ["info_general"]),
    por_pagina_model: {
      get() {
        return this.cantidad_por_pagina;
      },
      set(value) {
        this.setCantidadPorPagina(value);
      },
    },
    pagina_model: {
      get() {
        return this.pagina_actual;
      },
      set(value) {
        this.setPaginaActual(value);
      },
    },
    id_proveedor_model: {
      get() {
        return this.id_proveedor;
      },
      set(value) {
        this.setIdProveedor(value);
      },
    },
    especificacion_model: {
      get() {
        return this.especificacion;
      },
      set(value) {
        this.setEspecificacion(value);
      },
    },
  },
  methods: {
    ...mapActions("etapaResultadosPreliminares", [
      "obtenerResultadosPreliminares",
      "obtenerParticipante",
      "obtenerProveedores",
      "descargarDocumento",
    ]),
    ...mapMutations("etapaResultadosPreliminares", [
      "setCantidadPorPagina",
      "setPaginaActual",
      "setIdProveedor",
      "setEspecificacion",
      "setParticipante",
    ]),
    paginar(filtros) {
      const { cantidad_por_pagina, pagina } = filtros;
      this.pagina_model = pagina;
      this.por_pagina_model = cantidad_por_pagina;
      this.obtenerResultadosPreliminares();
    },
    async verDetalle(id) {
      await this.obtenerParticipante(id);
      this.showModal = true;
    },
  },
  watch: {
    showModal(val) {
      if (!val) {
        this.setParticipante({});
        this.$refs.inputPrecioMT.clear();
        this.$refs.inputTotalMT.clear();
      }
    },
  },
  created() {
    this.obtenerResultadosPreliminares();
    this.obtenerProveedores();
  },
};
</script>