<template>
  <section>
    <v-row v-if="!isUaci">
      <v-col cols="12" sm="12">
        <p>
          Durante este proceso se revisará cada recurso por los proveedores, de
          haber cambios en las evaluaciones se notificará.
        </p>
        <p>Sus recursos presentados son:</p>
      </v-col>
    </v-row>
    <v-row v-if="isUaci" class="mt-5">
      <v-col cols="12">
        <v-btn text color="#404a7a" @click="downloadDoc(items.data?.actaComiteEvaluador)" :disabled="items.data?.actaComiteEvaluador ? false : true">
          {{ actaCEvaluador || "Sin Acta de comité evaluador" }}
          <v-icon>mdi-download</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row v-if="isUaci">
      <v-col cols="12" sm="4">
        <v-file-input
          prepend-icon=""
          outlined
          label="Acta de comité evaluador *"
          prepend-inner-icon="mdi-paperclip"
          v-model="documento"
          @input="$v.documento.$touch()"
          @blur="$v.documento.$touch()"
          :error-messages="documentoErrors"
          accept="application/pdf"
        >
        </v-file-input>
      </v-col>
      <v-col cols="12" sm="4">
        <v-btn
          color="#404a7a"
          :block="$vuetify.breakpoint.xs ? true : false"
          @click="guardarDocumento()"
          dark
          >Guardar</v-btn
        >
      </v-col>
    </v-row>

    <v-row v-if="isUaci">
      <v-col cols="12" sm="4" md="3" offset-md="9">
        <v-autocomplete
          @change="getEvaluacionesPaac"
          placeholder="Seleccione"
          outlined
          label="OBS"
          :items="selectObs.data"
          item-text="nombre"
          item-value="id"
          v-model="id_obs"
          clearable
        ></v-autocomplete>
      </v-col>
    </v-row>
    <v-row>
      <v-col v-if="isUaci" cols="12">
        <DataTable
          :headers="headers"
          :items="items.data?.data"
          v-models:select="por_pagina"
          v-models:pagina="pagina"
          :total_registros="total_registros"
          @paginar="paginar"
          @sendItem="sendItem"
        >
          <template v-slot:[`item.estado`]="{ item }">
            <v-chip
              small
              :color="item.estado == 'Pendiente' ? '#ffeba6' : '#57cc99'"
              label
            >
              {{ item?.estado }}</v-chip
            >
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon color="secondary" @click="sendItem(item.id_proceso_obs)">
              mdi-briefcase-check-outline
            </v-icon>
          </template>
        </DataTable>
      </v-col>
      <v-col v-if="!isUaci" cols="12">
        <DataTable
          :headers="headers_proveedor"
          :items="items.data?.rows"
          v-models:select="por_pagina"
          v-models:pagina="pagina"
          :total_registros="total_registros"
          @paginar="paginar"
        >
          <template #[`item.fecha_carga`]="{ item }">
          {{ moment(item.fecha_carga).format("DD/MM/YYYY") }}
          </template>
          <template v-slot:[`item.proveedor`]="{ item }">
            {{ item.Usuario.Proveedor.nombre }}
          </template>
          <template v-slot:[`item.acciones`]="{ item }">
            <v-icon color="primary" @click="downloadDoc(item)">
              mdi-download
            </v-icon>
          </template>
        </DataTable>
      </v-col>
    </v-row>
  </section>
</template>

<script>
import DataTable from "@/components/DataTableComponent.vue";
import PaacServices from "@/services/Paac.services";
import LegacyValidations from "@/utils/legacy-validations";
import { required } from "vuelidate/lib/validators";

function fileNameValid(value) {
  if (!value) {
    return true;
  }
  let extension = /(\.pdf)$/i;
  let file = value;
  return extension.test(file.name);
}

export default {
  name: "EvaluacionRecursoRevisionComponent",
  components: {
    DataTable,
  },
  props: {
    isUaci: {
      type: Boolean,
      default: false,
    },
    idProceso: {
      type: String,
    },
  },
  validations: {
    documento: { 
      required, 
      fileNameValid,
      fileSizeValid: LegacyValidations.maxFileSizeRule(), 
    },
  },
  data: () => ({
    id_proceso_obs: null,
    documento: null,
    id_tipo_documento: null,
    pagina: 1,
    por_pagina: 5,
    total_registros: 0,
    id_obs: null,
    selectObs: [],
    headers: [
      {
        text: "OBS",
        align: "start",
        sortable: true,
        value: "id_nombre",
        width: "80%",
      },
      { text: "Estado", value: "estado", sortable: true },
      { text: "Acciones", align: "center", value: "actions" },
    ],
    headers_proveedor: [
      { text: "Recurso", align: "start", value: "nombre_documento" },
      { text: "Fecha de carga", align: "start", value: "fecha_carga" },
      { text: "Proveedor", align: "start", value: "proveedor" },
      { text: "Acciones", align: "center", value: "acciones" },
    ],
    items: [],
    actaCEvaluador: null,
  }),

  methods: {
    async getEvaluacionesPaac() {
      let params = {
        page: this.pagina,
        per_page: this.por_pagina,
        pagination: true,
        id_obs: this.id_obs,
      };
      this.items = await this.services.Paac.getEvaluacionPaac(
        this.idProceso,
        params
      );
      this.total_registros = this.items.data.count;
      if (this.items.data?.actaComiteEvaluador) { 
        this.actaCEvaluador = this.items.data?.actaComiteEvaluador.split("/")[1]
      }
    },
    async getSelectObs() {
      this.selectObs = await this.services.Paac.getObsUaci(this.idProceso);
    },
    async guardarDocumento() {
      try {
        this.$v.$touch();
        if (this.$v.$invalid) {
          this.temporalAlert({
            show: true,
            type: "error",
            message: "Documento invalido",
          });
          return false;
        }
        let formData = new FormData();
        let id_tipo_documento = this.items.data.id_tipo_documento;
        formData.append("documento", this.documento);
        formData.append("id_tipo_documento", id_tipo_documento);
        const response =
          await this.services.Paac.postDocumentoRecursoRevisionUaci(
            this.idProceso,
            formData
          );
        if (response.status == 201) {
          this.temporalAlert({
            show: true,
            type: "success",
            message: response.data.message,
          });

          this.getEvaluacionesPaac();
          this.$v.reset();
        }
        this.documento = null;
        this.id_tipo_documento = null;
      } catch (response) {}
    },

    paginar(filtros) {
      const { cantidad_por_pagina, pagina } = filtros;
      this.pagina = pagina;
      this.por_pagina = cantidad_por_pagina;
      this.getEvaluacionesPaac();
    },
    sendItem(id_proceso_obs) {
      this.id_proceso_obs = id_proceso_obs;
      this.$router.push({
        name: "listar-ofertas-subprocesos-paac",
        params: {
          id_proceso: this.idProceso,
          id_proceso_obs: this.id_proceso_obs,
        },
      });
    },
    async downloadDoc(item) {
   
      const ruta = item?.ruta_documento || item
      const response = await PaacServices.downloadDocumentoProcesoCompra(
        ruta
      );
      if (!response) {
        this.temporalAlert({
          show: true,
          message: "Ocurrió un error al descargar el documento",
          type: "error",
        });
      } else {
        const fileUrl = window.URL.createObjectURL(new Blob([response.data]));
        const fileLink = document.createElement("a");
        fileLink.href = fileUrl;
        fileLink.setAttribute("download", `${item?.nombre_documento || item.split("/")[1]}`);
        document.body.appendChild(fileLink);
        fileLink.click();
      }
    },
  },

  computed: {
    documentoErrors() {
      const errors = [];
      if (!this.$v.documento.$dirty) return errors;
      !this.$v.documento.required && errors.push("El archivo es requerido");
      !this.$v.documento.fileNameValid &&
        errors.push("El archivo debe ser un PDF");
      !this.$v.documento.fileSizeValid && LegacyValidations.pushToErrorsArray(errors);
      return errors;
    },
  },
  created() {
    this.getEvaluacionesPaac();
    this.getSelectObs();
  },
};
</script>
