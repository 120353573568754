<template>
  <section class="pb-10">
    <v-row v-if="!isUaci">
      <v-col cols="6">
        <v-textarea
          v-model="nueva_consulta"
          label="Escriba su consulta"
          outlined
          no-resize
          rows="3"
          :error-messages="nuevaConsultaError"
        ></v-textarea>
      </v-col>
      <v-col>
        <v-file-input
          class="mt-5"
          append-icon="mdi-paperclip"
          outlined
          label="Adjunto (opcional)"
          placeholder="Agregue un archivo adjunto"
          prepend-icon=""
          v-model="adjunto"
          :error-messages="adjuntoError"
          accept="application/pdf"
          enctype="multipart/form-data"
        />
      </v-col>
      <v-col cols="2">
        <v-btn
          outlined
          class="mt-8"
          @click="agregarConsulta"
          :loading="isLoadingAgregar"
          color="secondary"
        >
          Agregar
        </v-btn>
      </v-col>
    </v-row>
    <!-- Consultas publicadas en expansion panels -->
    <v-row>
      <v-col cols="12">
        <v-expansion-panels class="expansion-panels-consultas" multiple>
          <consulta-component
            :is-uaci="isUaci"
            v-for="consulta in consultasOrdenadas"
            :key="consulta.id"
            :consulta="consulta"
          />
        </v-expansion-panels>
      </v-col>
    </v-row>
  </section>
</template>
<script>
import ConsultaComponent from "../ConsultaUaciComponent.vue";
import LegacyValidations from "@/utils/legacy-validations";
import { mapActions, mapState } from "vuex";
import { required } from "vuelidate/lib/validators";

// Validar que el archivo adjunto sea un pdf
const isPdf = (value) => {
  if (value) {
    return value.type == "application/pdf";
  }
  return true;
};

export default {
  name: "EtapaConsultasUACIComponent",
  components: {
    ConsultaComponent,
  },
  props: {
    isUaci: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      nueva_consulta: "",
      adjunto: null,
    };
  },
  validations: {
    nueva_consulta: {
      required,
    },
    adjunto: {
      maxSize: LegacyValidations.maxFileSizeRule(),
      isPdf,
    },
  },
  computed: {
    ...mapState("etapaConsultasStore", ["consultas", "isLoadingAgregar"]),
    nuevaConsultaError() {
      const errors = [];
      if (!this.$v.nueva_consulta.$dirty) return errors;
      !this.$v.nueva_consulta.required &&
        errors.push("La consulta no puede estar vacía");
      return errors;
    },
    adjuntoError() {
      const errors = [];
      if (!this.$v.adjunto.$dirty) return errors;
      !this.$v.adjunto.isPdf && errors.push("El archivo adjunto debe ser un pdf");
      !this.$v.adjunto.maxSize && LegacyValidations.pushToErrorsArray(errors);
      return errors;
    },
    consultasOrdenadas() {
      //  Ordenar consultas mostrando las mas recientes prime
      return this.consultas.sort((a, b) => {
        return new Date(b.fecha) - new Date(a.fecha);
      });
    },
  },
  methods: {
    ...mapActions("etapaConsultasStore", ["responderConsulta"]),
    async agregarConsulta(id_estado = null) {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      const formData = new FormData();
      formData.append("consulta", this.nueva_consulta);
      formData.append("documento", this.adjunto);

      const response = await this.responderConsulta(formData);
      if (response.status == 201) {
        // Reiniciar el campo de consulta
        this.nueva_consulta = "";
        // Reiniciar el campo de adjunto
        this.adjunto = null;
        // Reiniciar validaciones
        this.$v.$reset();
      }
    },
  },
};
</script>
<style lang="scss">
.expansion-panels-consultas {
  .v-expansion-panel-header {
    // Colocar borde en la parte inferior
    // border-bottom: 1px solid #e0e0e0;
    border: 1px solid red;
  }
  .v-expansion-panel {
    .v-expansion-panel-content {
      background-color: var(--v-bgMinsal-base);
    }

    .v-expansion-panel-header {
      border-bottom: 1px solid #e0e0e0 !important;
      padding-left: 10px !important;
    }
  }
  .v-expansion-panel:before {
    box-shadow: none !important;
  }
  .v-expansion-panel::before {
    box-shadow: none !important;
  }
}
</style>
